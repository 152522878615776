<template>
    <div class="taskExecution">
        <header>
            <div class="tabs">
                <el-tabs v-model="mainTab" @tab-click="mainTabClick" class="main-tab">
                    <el-tab-pane v-if="subMenu.toPerformTasks" label="待执行任务" name="1"></el-tab-pane>
                    <el-tab-pane v-if="subMenu.missionexecutionrecord" label="任务执行记录" name="2"></el-tab-pane>
                </el-tabs>
            </div>
            <div class="searchs">
                <el-form :inline="true" :model="query" class="demo-form-inline">
                    <el-form-item v-if="mainTab == '2'" label="年度：" prop="actionYear">
                        <el-date-picker
                            v-model="actionYear"
                            type="year"
                            placeholder="请选择"
                            value-format="YYYY"
                        />
                    </el-form-item>
                    <el-form-item label="重大任务名称：" prop="actionName">
                        <el-input placeholder="请输入" v-model="query.actionName"></el-input>
                    </el-form-item>
                    <el-form-item label="指标名称：" prop="indexName">
                        <el-input placeholder="请输入" v-model="query.indexName"></el-input>
                    </el-form-item>
                    <el-form-item label="牵头单位：">
                        <el-cascader
                            @change="handleChange"
                            ref="cascader"
                            filterable
                            clearable
                            v-model="actionDeptName"
                            :props="defaultProps"
                            :key="query.actionDeptId"
                        />
                    </el-form-item>
                </el-form>
                <div class="btns">
                    <el-button v-if="mainTab == '2'" @click="exportFile" type="primary">导 出</el-button>
                    <el-button @click="search" type="primary">查 询</el-button>
                    <el-button @click="resize" plain>重 置</el-button>
                </div>
            </div>
        </header>
        <main>
            <div class="subToolbar">
                <div class="title" v-if="mainTab == '1'">待执行任务</div>
                <div class="title" v-if="mainTab == '2'">任务执行记录列表</div>
            </div>
            <div class="table">
                <el-table
                    :data="tableData"
                    :default-sort="{ prop: 'createTime', order: 'descending' }"
                    style="width: 100%; height: 100%"
                    header-row-class-name="table-header"
                    height="calc(100% - 40px)"
                    :header-cell-style="{
                        background: '#F7F8FA',
                        color: '#333333',
                        height: '54px',
                        'font-size': '14px',
                    }"
                    @cell-dblclick="dblclickRow"
                >
                    <!-- <el-table-column type="selection" width="55" /> -->
                    <el-table-column type="index" width="80" label="序号" class-name="el-table-fixed-column--left" />
                    <el-table-column prop="actionDeptName" label="牵头单位"> </el-table-column>
                    <el-table-column prop="actionName" label="重大任务"> </el-table-column>
                    <el-table-column prop="indexName" label="指标名称"> </el-table-column>
      
    
                    <el-table-column prop="indexType" label="任务类型">
                        <template #default="{ row }">
                            <span v-if="row.indexType == '0'" slot="reference">定量任务</span>
                            <span v-else slot="reference">定性任务</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="orgName" label="责任单位" min-width=""> </el-table-column>
                    <el-table-column v-if="envType === 'intranet'" prop="classification" label="密级" min-width="200">
                        <template #default="scope">
                            <div v-if="scope.row.actionSecret === '0'">
                                {{ classificationMap[scope.row.classification] }}
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="deadLine" v-if="mainTab == 1" label="截止日期"> </el-table-column>
                    <el-table-column prop="orgNum" label="目标值" width="80" class-name="textCenter"> </el-table-column>
                    <el-table-column prop="completeNum" label="完成值"> </el-table-column>

                    <el-table-column prop="writeTime" v-if="mainTab == 2" label="填报日期" class-name="textCenter">
                    </el-table-column>
                    <el-table-column prop="doneFlag" label="状态">
                        <template #default="{ row }">
                            <span>{{ row.doneFlag == '0' ? '未完成' : '已完成' }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="操作" width="130" class-name="textCenter">
                        <template #default="{ row }">
                            <el-button
                                type="text"
                                size="small"
                                v-if="mainTab == 1 && userBtns.toperformtasks_report"
                                @click="fillItem(row)"
                                >填报</el-button
                            >
                            <el-button
                                type="text"
                                size="small"
                                v-if="mainTab == 2 && userBtns.missionexecutionrecord_check"
                                @click="openDetail(row)"
                                >查看</el-button
                            >
                            <el-button
                                class="button"
                                type="text"
                                size="small"
                                v-if="mainTab == 2 && userBtns.missionexecutionrecord_update"
                                @click="editItem(row)"
                                >修改</el-button
                            >
                        </template>
                    </el-table-column>
                </el-table>
                <div class="pagination">
                    <el-pagination
                        v-model:currentPage="query.page"
                        v-model:page-size="query.size"
                        :page-sizes="[15, 30, 50, 100]"
                        background
                        layout="total, sizes, prev, pager, next, jumper"
                        :total="total"
                        @size-change="getList"
                        @current-change="getList"
                    >
                    </el-pagination>
                </div>
            </div>
        </main>
    </div>
</template>
<script setup>
import { ref, reactive, computed, watch, onMounted, onUnmounted, getCurrentInstance } from 'vue';
import axios from 'axios';
import $store from '@/store';
import { useRouter, useRoute } from 'vue-router';
import { ElMessage } from 'element-plus';
import { useStore } from 'vuex';

const { proxy } = getCurrentInstance();
const envType = computed(() => {
    return process.env.VUE_APP_API_ENV_IRONMENT;
});
const classificationMap = {
    1: '秘密',
    2: '机密',
};
let store = useStore();
const roles = store.state.user.roles;
function isDisables(role) {
    let res = true;
    role.forEach((e) => {
        if (roles.indexOf(e) > -1) {
            res = false;
        }
        return res;
    });
    return res;
}
let mainTab = ref('1');
const router = useRouter();
const route = useRoute();
const emit = defineEmits(['close']);
const props = defineProps({});
let actionYear = ref('');
let query = ref({
    page: 1,
    size: 15,
    actionName: '',
    indexName: '',
    selectType: '',
    statuses: '',
    actionDeptId: '',
});
let total = ref(0);
const options = [
    {
        value: null,
        label: '全部',
    },
    {
        value: 1,
        label: '已提交',
    },
    {
        value: 0,
        label: '草稿',
    },
];
let actionDeptName = ref([]);
const defaultProps = {
    lazy: true,
    checkStrictly: true,
    async lazyLoad(node, resolve) {
        // console.log(node,);
        const { level } = node;
        let data = level > 0 ? node.value : 'main';
        level + 1;
        let res = await axios({
            url: '/front/sysOrganization/getByParentExternalId',
            method: 'get',
            params: {
                parentExternalId: data,
            },
        });
        if (res) {
            // console.log(res);
            let nodes = res.map((item) => {
                return {
                    label: item.organizationName,
                    value: item.externalId,
                    leaf: false,
                    // deptId: item.deptId,
                };
            });
            if (res.length == 0) {
                node.data.leaf = true;
            }
            resolve(nodes);
        }
    },
};
let tableData = ref([]);
function fillItem(row) {
    router.push({
        path: '/fillTask',
        query: {
            title: '填报',
            type: 1,
            id: row.unitId,
        },
    });
}
function editItem(row) {
    router.push({
        path: './fillTask',
        query: {
            title: '修改',
            type: 1,
            id: row.unitId,
        },
    });
}
function openDetail(row) {
    router.push({
        path: '/fillTask',
        query: {
            title: '查看',
            id: row.unitId,
        },
    });
}
function dblclickRow(row) {
    if (mainTab.value == 1) {
        fillItem(row);
    } else {
        openDetail(row);
    }
}
function getList() {
    query.value.selectType = '';
    query.value.statuses = '';
    if (mainTab.value == 2) {
        query.value.selectType = 2;
        query.value.statuses = '2,3';
    } else {
        query.value.selectType = 1;
        query.value.statuses = 2;
    }
    let method = mainTab.value == '2' ? 'tasksToBePerformed' : 'getPage';
    proxy.$api.frontIndexUnit[method]({...query.value,actionYear:actionYear.value}).then((res) => {
        if (res.code == 200) {
            tableData.value = res.data.records;
            total.value = res.data.total;
        }
    });
}
function search() {
    query.value.page = 1;
    getList();
}
const mainTabClick = (vm) => {
    mainTab.value = vm.props.name;
    getList();
};
let cascader = ref({});
// 点击联选节点触发事件
function handleChange(val) {
    let nodesInfo = cascader.value.getCheckedNodes()[0];
    console.log(nodesInfo);
    query.value.actionDeptId = nodesInfo.data.value;
}
function resize() {
    actionYear.value = '';
    query.value = {
        page: 1,
        size: 15,
        actionName: '',
        indexName: '',
        status: 2,
        actionDeptId: '',
    };
    actionDeptName.value = [];
    getList();
}

function exportFile() {
    let baseUrl = '/prod-api'
    let params = query.value
    let url = '/front/frontIndexUnit/export'
    let title = '任务执行记录列表.xlsx'
    let paramsArray = [];

    //拼接参数
    Object.keys(params).forEach((key) => paramsArray.push(key + '=' + params[key]));
    if (url.search(/\?/) === -1) {
        url += '?' + paramsArray.join('&');
    } else {
        url += '&' + paramsArray.join('&');
    }
    fetch(baseUrl + url, {
        method: 'get',
        headers: {
            Authorization: localStorage.getItem('token'),
        },
    }).then((res) => {
        res.blob().then((blob) => {
            var a = document.createElement('a');
            var url = window.URL.createObjectURL(blob);
            var filename = title//'任务执行记录列表.xlsx';
            a.href = url;
            a.download = filename;
            a.click();
            window.URL.revokeObjectURL(url);
        });
    }).catch(err =>{
        ElMessage.error('导出失败')
    });
}

const test = computed(() => {
    return null;
});
watch(() => {});
// 获取按钮权限
const userBtns = computed(() => {
    return store.state.user.userBtns;
});
//获取subMenu权限
const subMenu = computed(() => {
    return store.state.user.sub_menu;
});
onMounted(() => {
    mainTab.value = route.query.tab || '1';
    getList();
});
onUnmounted(() => {});
</script>
<style lang="stylus" scoped>
.taskExecution {
    height: calc(100% - 32px);
    header {
        height: 128px;
        background: #ffffff;
        box-shadow: 0 0 10px -6px rgba(0, 0, 0, 0.3);
        border-radius: 2px;
        padding: 16px 24px 0 24px;
        box-sizing: border-box;
        .searchs {
            display: flex;
            justify-content: space-between;
        }
        .tabs {
            display: flex;
            height: 50px;
            padding-bottom: 12px;
        }
        .btns {
            white-space: nowrap;
        }
    }
    main {
        margin-top: 16px;
        height: calc(100% - 144px);
        padding: 16px 24px 25px 24px;
        box-sizing: border-box;
        background: #ffffff;
        box-shadow: 0 0 10px -6px rgba(0, 0, 0, 0.3);
        border-radius: 2px;
        .subToolbar {
            height: 50px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            overflow: hidden;
        }
        .table {
            height: calc(100% - 92px);
            .table-header {
                height: calc(100% - 40px);
            }
            .pagination {
                height: 40px;
                padding-top: 10px;
                background: #fff;
                text-align: right;
                padding-right: 24px;
            }
        }
        /deep/.el-table th.el-table__cell {
            background: #f6f8fa;
        }
        .button{
            margin-left: 0px
        }
    }
}
</style>
